import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})
export class CandidateActivityService {

  constructor(private backendService: BackendService) { }

  private path = '/activities';

  getActivities (page){
    return this.backendService.get (this.path + "/" + page);
  }





}