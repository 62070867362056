<div class="cand-menu">

    <nav class="navbar navbar-expand-md pad0" [ngStyle]="getFirstStyles()">
        <div class="collapse navbar-collapse justify-content-center">
            <ul class="navbar-nav">
                <li *ngFor="let menuItem of menus">
                    <div class="menu-item" (click)="onMenuClick(menuItem)" 
                    style="cursor:pointer;"
                    [ngClass]="{'active': isActive(menuItem)}" [ngStyle]="getSecondStyles(menuItem)">
                        <i [attr.class]="menuItem.icon" class="pl-20"></i>
                        <a class="menu-link" [ngClass]="(getMenuText(menuItem) == 'Main (Written) Examination Form' && !isActive(menuItem))?'redColor':''">{{getMenuText(menuItem)}}</a>
                    </div>
                </li>

            </ul>

        </div>
    </nav>
</div>