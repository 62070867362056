import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
    providedIn: 'root'
})


export class NextExamService {


    private path = '/next-exam';


    constructor(private backendService:BackendService) { }

    
    getRequiredDocs() {

        return this.backendService.get(this.path + "/docs");
    }

    

    get() {

        return this.backendService.get(this.path );
    }
    isEligible() {

        return this.backendService.get(this.path + "/eligible" );
    }

      
    save(obj:any) {

        return this.backendService.post(this.path , obj);
    }
}