import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService implements CanActivate {

   
  constructor(private router: Router, 
    private authenticationService: AuthenticationService ) {}

canActivate(
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
   const isloggedIn = this.authenticationService.isLoggedIn ();
   
    if (!isloggedIn) {
      this.router.navigate (['/home/login']);
      return false;
    }
   
    return true;
}
}
