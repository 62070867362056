<div class="container-fluid" *ngIf="dataAvailable">
    <app-home-header [data]="data"></app-home-header>
    
    
    <div class="row bottom-container">
      <div class="col-md-12">
          <app-candidate-menu   [language]="language"></app-candidate-menu>
      </div>

    </div>

      <div class="row bottom-container">

     
    
      <div class="col-md-12">
        <app-main-body class="app-container"  [language]="language"></app-main-body>
      </div>
    </div>
          
      
     
    
</div>