import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit  } from '@angular/core';
import  Dropzone from 'dropzone'; 
import { AuthenticationService, CoreService } from '../services';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit ,AfterViewInit{

  @Input() url:any;
  @Input() msg: any;
  @Input() doctype:any;

  @Input() contextId:any;
  @Input() contextType:any;
  @Input() dropZoneId: any;
  @Input() minSize:any;
  @Input() maxSize:any;
  @Input() filetype:any;
  @Input() fileTypeErrorMesssage:any = "Please upload valid image file, allowed only image file type( Jpg,Jpeg,Png)";

  public dropZone: any;
 
  @Output() docUploaded = new EventEmitter<any>();
  @Output() docError = new EventEmitter<any>();

  constructor(private authenticationService: AuthenticationService,
              private coreService:CoreService) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void { this.dropZoneInit ();
  }

  dropZoneInit() {

    var token  = this.authenticationService.getAuthToken ();
  
  var ele  = document.getElementById (this.dropZoneId);
 
  if (!ele){
    return;
  }
  
    
    this.dropZone = new Dropzone("div#"+ this.dropZoneId, { 
      url: this.url,
      uploadMultiple: false,
      dictDefaultMessage: this.msg,
      maxFiles: 5, 
      headers: {
        contextid: this.contextId,
        contexttype: this.contextType,
        doctype:  this.doctype,
        token: token, 
        appname:this.coreService.getAppName (),
        usertype: this.coreService.getUserType ()
        
      },
      accept:  (file, done) => {   
           
           if(this.filetype.indexOf(file.type) >= 0){
           
            if (file.size >= this.minSize && file.size <= this.maxSize) { 
              this.docError.emit(" ");             
              done ();
            } else {              
              if (file.size < this.minSize ){
                this.docError.emit("Please upload more than or equal to "+ Math.floor ( this.minSize/1024) +" KB size images.");
                done ("Please upload more than or equal to "+ Math.floor ( this.minSize/1024) +" KB size images.");               
              }
              if (file.size > this.maxSize){
                this.docError.emit("Please upload less than or equal to  "+ Math.floor ( this.maxSize/(1024*1024)) +" MB size images.");
                done ("Please upload less than or equal to "+ Math.floor (  this.maxSize/(1024*1024)) +" MB size images.");               
              }             
            }        
          }
          else{ 
            this.docError.emit(this.fileTypeErrorMesssage);            
            done (this.fileTypeErrorMesssage);
          }        
      }
  });

 
    this.dropZone.on("success", (file, response) => { 
      if (response.documentid){
        this.docUploaded.emit (response);
      }else {
        this.dropZone.removeFile (file);
        alert  ("file upload failed..." + response.status);
        
      }
    }); 
  
  
    this.dropZone.on("complete", (file, done) => { 
      this.dropZone.removeFile (file);
      //done ("Your documents uploaded sucessfully.");
    }); 
  
  }

}
