<div class="row rm-header">

  <div class="col-md-2">
    <nav class="navbar-expand-lg fixed-top">
      <button class="navbar-toggler" type="button" (click)="openNav()">
        <i class="fa fa-bars text-muted" aria-hidden="true"></i>
      </button>

      <div id="mySidenav" class="sidenav">
        <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
        <ul class="navbar-nav" *ngIf="user">
          <li *ngFor="let menu of menu">
            <div class="menu-item" (click)="onMenuClickBtn(menu)" style="cursor:pointer;" [ngClass]="{'active': isActive(menu)}"
              [ngStyle]="getSecondStylesMenu(menu)">
              <a class="menu-link"><i [attr.class]="menu.icon" class="pl-20"></i>&nbsp;&nbsp;{{getMenuText(menu)}}</a>
            </div>
          </li>
        </ul>

        <ul class="navbar-nav font-weight-bold" *ngIf="lang && !user" [ngStyle]="getFirstStyle()">
            <li [ngClass]="{'active': isHome()}" [ngStyle]="getThirdStyles('/home')">
              <a [routerLink]="['/']">{{data.labels.HOME.MENU_HOME[lang.code]}}</a>
            </li>
            <li [ngClass]="{'active': isFaq()}" [ngStyle]="getThirdStyles('/home/faq')">
              <a [routerLink]="['faq']">{{data.labels.HOME.MENU_FAQ[lang.code]}}</a>
            </li>
            <!-- <li  [ngClass]="{'active': isTestcenter()}" [ngStyle]="getThirdStyles('/home/testcenter')">
              <a  [routerLink]="['testcenter']">{{data.labels.HOME.MENU_TESTCENTERLIST[lang.code]}}</a>
            </li>
            <li [ngClass]="{'active': isPracticetest()}" [ngStyle]="getThirdStyles('/home/practicetest')">
              <a  [routerLink]="['practicetest']">{{data.labels.HOME.MENU_PRACTICETEST[lang.code]}}</a>
            </li> -->
            <li [ngClass]="{'active': isContactus()}" [ngStyle]="getThirdStyles('/home/contactus')">
              <a  [routerLink]="['contactus']">{{data.labels.HOME.MENU_CONTACTUS[lang.code]}}</a>
            </li>

            <li  *ngIf="user" [ngStyle]="getThirdStyles('/candidate/dashboard')">
                <a  [routerLink]="['/candidate/dashboard']">Dashboard</a>
              </li> 
          </ul>
      </div>
    </nav>

    <a class="navbar-brand" [routerLink]="['/']">
      <img [attr.src]="logoUrl"  height="130px" class="rm-banner">
    </a>
  </div>

  <div class="col-md-7 banner-container rm-sm-hide">

    <div class="banner-text" *ngIf="data.settings.header.banner_type == 'banner_text'" 
            [innerHtml]="headerText | safehtml"></div>

        
            
    <img class="rm-banner-img" [attr.width]="banner_width" 
        *ngIf="data.settings.header.banner_type == 'banner_image'"
      [src]="headerImageUrl">
  </div>

  <div class="col-md-3 rm-langdd">
    <div class="row layout">
      <div ngbDropdown *ngIf="showLanguage() &&  user" style="margin-left:auto;margin-right: 10px;min-width: 100px;">
        <button class="btn" [ngStyle]="getFirstStyles()" id="rmLangDD" ngbDropdownToggle>{{currentLanguage.name}}</button>
        <div ngbDropdownMenu aria-labelledby="rmLangDD" style="overflow-x: hidden;max-height: 150px;">
          <button *ngFor="let lang of getVisibleLangs (data.languages)" ngbDropdownItem style="overflow-y:auto;max-height:100px;"
            (click)="onLanguageChange(lang)">{{lang.name}}</button>

        </div>
      </div>

      

      <div ngbDropdown class="d-inline-block" *ngIf="user">
   
        <div id="menuProfile" ngbDropdownToggle 
       >
       
             {{user.email}}  
    </div>
       
       <div ngbDropdownMenu aria-labelledby="menuProfile" style="width: 200px;">



        <button ngbDropdownItem  (click)="onMenuClick('CHANGEPASSWORD')" >
          <i class="fas fa-key" ></i> Change Password</button> 
        <button ngbDropdownItem  (click)="onMenuClick('PROFILE')">  <i class="fas fa-user"></i> Profile</button> 
        <button ngbDropdownItem  (click)="onMenuClick('LOGOUT')">  <i class="fas fa-lock"></i> Logout</button> 
 

       
 

       </div>
     </div>




      <div ngbDropdown *ngIf="showLanguage() && !user" style="margin-left:auto;margin-right: 10px;min-width: 100px;">
        <button class="btn" [ngStyle]="getFirstStyles()" id="rmLangDD" ngbDropdownToggle>{{currentLanguage.name}}</button>
        <div ngbDropdownMenu aria-labelledby="rmLangDD" style="overflow-x: hidden;max-height: 150px;">
          <button *ngFor="let lang of getVisibleLangs (data.languages)" ngbDropdownItem (click)="onLanguageChange(lang)">{{lang.name}}</button>

        </div>
      </div>


     



    </div>
  </div>
</div>