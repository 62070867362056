import { NgModule } from '@angular/core'; 

import { Routes, RouterModule } from '@angular/router';

 
export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    data: {
      title: 'Registration Home'
    },  
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
      }
    ]
  },
  {
    path: 'candidate', 
    children: [
      {
        path: '',
        loadChildren: () => import('./candidate/candidate.module').then(m => m.CandidateModule),
      }
    ]
  },
  {
    path: 'applications', 
    children: [
      {
        path: '',
        loadChildren: () => import('./application/application.module').then(m => m.ApplicationModule),
      }
    ]
  }
   
];


@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
