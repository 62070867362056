import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { StoreService } from 'src/app/shared/services';

@Component({
  selector: 'app-main-body',
  templateUrl: './main-body.component.html',
  styleUrls: ['./main-body.component.scss']
})
export class MainBodyComponent implements OnInit, OnDestroy {

  @Input() language:any;
  storeSub:any;
  data: any;
  constructor(private store: StoreService) { }

  ngOnInit() {
    this.storeSub = this.store.getSett ()
    .subscribe (r=>{
     // console.log ("Sending this to footer ...", r);
      this.data = r;
    })
  }



  ngOnDestroy (){
    if (this.storeSub){
      this.storeSub.unsubscribe();
    }
  }

}
