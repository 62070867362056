import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

   
  constructor(private backendService: BackendService) { }


  getHallTicketStatus() {
    return this.backendService.get("/application/hallticket");
  }

  saveCandidateStatus(tabid: String, examId: String) {
    return this.backendService.post("/application", { tabid: tabid, examid: examId });
  }

  getApplicationTabs(examId: String) {
    return this.backendService.post('/application/app-tabs', { examid: examId });
  }
  getCandidateExamsDashboard() {
    return this.backendService.get("/dashboard/exams");
  }

  getCandidateExamsDashboardAll() {
    return this.backendService.get("/dashboard/examsall");
  }

  submitApplication(tabid: any, examId: String) {
    return this.backendService.post("/application/submit", { tabid: tabid, examid: examId });
  }

  enableFormFilling(examId: String) {
    return this.backendService.post("/application/enable-form", { examid: examId });
  }

  disableFormFilling(examId: String) {
    return this.backendService.post("/application/disable-form", { examid: examId });
  }

  getSubmittedApps() {
    return this.backendService.get("/application/submitted-apps");
  }

  getSubmissionStatus() {
    return this.backendService.get("/application/status");
  }


  upadateDownloadCount(examid: any, examname: any, count: any) {
    return this.backendService.post("/application/count", { examid: examid, examname: examname, downloadcount: count });

  }

  setCandidateDetails(examName: String) {
    return this.backendService.post("/application/set-default-details", { examname: examName});
  }

  getAllAppsStatus() {
    return this.backendService.get("/application/apps-status");
  }
}
