 

 <div class="container-fluid" *ngIf="dataAvailable">
    <app-home-header [data]="data"></app-home-header>
    <app-home-menu class="rm-sm-hide" [data]="data" [currentLanguage]="language"></app-home-menu>
   <div class="row rm-home-container">
       <div class="col-md-12" style="opacity:0.9; margin-bottom:20px;" >
    <router-outlet></router-outlet>
</div>
</div>
    <app-home-footer [data]="data" ></app-home-footer>
</div>
