import { Component, OnInit, Input, EventEmitter, Output, NgZone, ElementRef, ViewChild } from '@angular/core';
import { WindowRef, PaymentService, AuthenticationService, StoreService, AlertService } from '../../services/index'; 
import { Router } from '@angular/router';
import { first } from "rxjs/operators";

@Component({
  selector: 'payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  @Input() examId: any;
  @Output() paymentStatus: EventEmitter<any> = new EventEmitter<any>(); 
  rzp1: any;
  user: any;
  orderDetails: any;
  error: string;
  success_message: string;
  currentLanguage: any;
  storeSub: any;
  isPaid: boolean;
  payEngine:any;
  feesPaid: any = [];

  constructor(
    private alert: AlertService,
    private zone: NgZone,
    private store: StoreService,
    private winRef: WindowRef,
    private paymentService: PaymentService) { }

  ngOnInit() {
    // console.log("Payment exam", this.examId)
    this.getFeesPaidExams()
    this.listenToLang();
    this.checkForPayment();
  }
  // checkForPayment() {

  //   this.paymentService.isPaid()
  //     .pipe(first())
  //     .subscribe(res => {
  //       if (res["paid"] && res["paid"] == true) {

  //         this.isPaid = true;
  //         return;

  //       } else {
  //         this.isPaid = false;
  //         return;
  //       }
  //     });
  // }
checkForPayment() {
  for(let i=0; i<this.feesPaid.length; i++) {
    // console.log("isPaidFees", examId, this.feesPaidExams[i]?.examid)
    if (this.feesPaid[i]?.examid === this.examId) {
      return this.isPaid = true;
    }
  }
  this.isPaid = false;
}
  
  getFeesPaidExams() { 
    this.paymentService.feesPaidExams()
      .pipe(first())
      .subscribe(res => {
        if (res) { 
         // console.log(res)
          this.feesPaid = res; 
        }  
      });
  }

  handleHDFC (res){
    const form = document.createElement('form');
    form.style.display = 'none';
    form.method = "POST";
    form.action = res.options.url;

    const encRequest = document.createElement('input');
    encRequest.name = "encRequest";
    encRequest.id = "encRequest";
    encRequest.value = res.options.encRequest;
    form.appendChild(encRequest);

    const accessCode = document.createElement('input');
    accessCode.name = "access_code";
    accessCode.id = "access_code";
    accessCode.value = res.options.access_code;
    form.appendChild(accessCode);
    
    
    document.body.appendChild(form);
    form.submit();
  }

  initPay() {

    this.paymentService.createOrder(this.examId)
      .pipe(first())
      .subscribe( (res:any) => {

        this.orderDetails = res["order"]
        var options = res["options"];

        if (res["error"]) {
          if (res["message"]){
            let text = res["message"][this.currentLanguage.code] || 
                          res["message"][this.store.defaultLanguage.code];
            this.alert.e (text);
          }
           
          return;
        }

        this.payEngine = res.options.payment_engine;
       // console.log ("see what it it s...", this.payEngine);
        if (this.payEngine == "billdesk"){ 

          ///now you need to post .. 

          const form = document.createElement('form');
          form.style.display = 'none';
          form.method = "POST";
          form.action = res.options.url;

          const msgInput = document.createElement('input');
          msgInput.name = "msg";
          msgInput.id = "msg";
          msgInput.value = res.options.msg;
          form.appendChild(msgInput);
          
          document.body.appendChild(form);
          form.submit();

          //window.open (res.options.url, "__self");
          return;
        }else  if (this.payEngine == "hdfcpay"){ 
          return this.handleHDFC (res);
        }

       
        options["handler"] = this.paymentResponseHander.bind(this);

        options["modal"] = {
          'ondismiss': this.paymentCancelHander.bind(this)
        };

        this.rzp1 = new this.winRef.nativeWindow.Razorpay(options);
        this.rzp1.open();

      });
  }




  paymentResponseHander(response) {

    this.orderDetails["payment_id"] = response["razorpay_payment_id"];
    this.paymentService.updatePaymentRespose(this.orderDetails).pipe(first()).subscribe(res => {
      console.log ("I am going to reload ...");
      window.location.reload();
      if (res["error"]) {
        this.error = res["message"] && res["message"][this.currentLanguage.code] || res["message"][this.store.defaultLanguage.code];
        this.paymentStatus.emit(this.error);
        return;

      } else {
        this.success_message = res["message"][this.currentLanguage.code] || res["message"][this.store.defaultLanguage.code];
        this.zone.run(() => {
          this.paymentStatus.emit(this.success_message);
        });

        return;
      }
    });

  }



  paymentCancelHander() {

    //  alert("Payment cancelle" +  response);

  }

  listenToLang() {
    this.storeSub = this.store.getLang().subscribe(lang => {
      this.currentLanguage = lang;
    });

  }


  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }

}
