import { Component, OnInit } from '@angular/core';
import { HomeService, StoreService } from 'src/app/shared/services';  
import { first } from "rxjs/operators";

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss']
})
export class FullLayoutComponent implements OnInit {



  data: any = {};
  dataAvailable = false;
  storeSub: any;
  language: any;


  constructor(private homeService: HomeService,
    private store: StoreService) { }

  ngOnInit() {
    this.homeService.getSettings()
      .pipe(first())
      .subscribe(res => { 
        this.data = res;
        this.store.onSett ({data: res});
        this.dataAvailable = true;
        this.listenToLang();
      });

  }

  listenToLang() {
    this.storeSub = this.store.getLang()
      .subscribe(lang => {
        this.language = lang; 
      });

  }


  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }

}
