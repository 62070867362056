import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import * as moment from 'moment';
@Pipe({
  name: 'qdate'
})
export class QDatePipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) {}

  transform(value: any, args?: any): any {
      if (moment(value).isValid){
          return moment (value).format ("DD-MM-YYYY hh:mm a");
      }
      return '';
  }

}
