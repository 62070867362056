import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-home-footer',
  templateUrl: './home-footer.component.html',
  styleUrls: ['./home-footer.component.scss']
})
export class HomeFooterComponent implements OnInit, OnChanges {

  @Input () data:any;
  pw:any = ""
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes.data){
        var d = this.data || {};
        d.settings  = d.settings || {};
        d.settings.header =  d.settings.header || {};
        this.pw = d.settings.header.poweredby || '';
      }
  }


  getFirstStyles() {
   
    var setting = {
      'background-color': '#001f3f',
      'color': 'white'
    }


      if (this.data && this.data.settings && this.data.settings.header &&  this.data.settings.header.firstoption ){
        setting["background-color"]=   this.data.settings.header.firstoption
        
      }
    return setting;
}

}
