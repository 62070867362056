import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {


  private path = '/order';
  private payment_path = '/payment';
  private other_path = '/other';


  constructor(private backendService: BackendService) { }
  getOrderInfo() {
    return this.backendService.get(this.path + "/latest");
  }

  getOrderByExamid(examid: String) {
    return this.backendService.post(this.path + "/order-by-examid", {examid: examid});
  }

  createOrder(examId: string) {
    return this.backendService.post(this.path, { exam_id: examId });
  }
  updatePaymentRespose(orderDetails: object) {
    return this.backendService.post(this.payment_path, orderDetails);
  }
  isPaid() {
    return this.backendService.get(this.other_path + "/ispaid");
  }
  
  feesPaidExams() {
    return this.backendService.get(this.other_path + "/fees-paid-exams");
  }


}
