 
    <div class="row page-container">
      <div class="col-md-12">
      <router-outlet></router-outlet>
     </div>
    </div>
    <div class="row">
      <div class="col-md-12">
    <app-home-footer [data]="data"></app-home-footer>
    </div>
  </div>
    
 