import { Component, OnInit, Input } from '@angular/core'; 
import { AuthenticationService, StoreService } from '../../services';
import {  Router } from '@angular/router';
@Component({
  selector: 'app-home-menu',
  templateUrl: './home-menu.component.html',
  styleUrls: ['./home-menu.component.scss']
})
export class HomeMenuComponent implements OnInit {

  @Input () data:any; 
  @Input() currentLanguage:any = {};
  
  user:any ;


  lang:any;
  
  constructor( 
     private authenticationService:AuthenticationService,
     private store: StoreService,
    private router:Router) { }

  ngOnInit() {
   
   this.lang =  this.store.defaultLanguage;

   this.user = this.authenticationService.getUserDetails ();
   //console.log ("what is user ?", this.user);
  }

  isActiveMenu (m){
    let path = this.router.url;
    return path.indexOf ( m.toLowerCase()) >= 0;
  //  console.log ("here is path => ", path, " and ", m);
      
  }

  isHome (){
    this.getFirstStyles();
    return this.router.url == '/home';    
  }

  getFirstStyles() {
     
    var setting = {
      'background-color': this.data.settings.header.firstoption ? this.data.settings.header.firstoption : '#001f3f',
      'color': 'white'
    }
    return setting;
 }


 getThirdStyles(menuItem) {
  
  var setting={};
  if(this.router.url.toLowerCase() == menuItem){
 
  if(this.data && this.data.settings){
  setting = {
    'background-color': this.data.settings.header.thirdoption ? this.data.settings.header.thirdoption : '#e0ac69',
    'color': 'white'
  }
 }
}else{
  if(this.data && this.data.settings){
    setting = {
      'background-color': this.data.settings.header.firstoption ? this.data.settings.header.firstoption : '#e0ac69',
      'color': 'white'
    }
   }

}
  return setting;
}

  isFaq(){
    return this.router.url == '/home/faq';    
  }
  
  isTestcenter(){
    return this.router.url == '/home/testcenter';    
  }

  isPracticetest(){
    return this.router.url == '/home/practicetest';    
  }

  isContactus(){
    return this.router.url == '/home/contactus';    
  }

  showMenu (m){
    if (m.enable === undefined){
      return true; 
    }
    if (m.enable){
      return true;
    }
    return false;
  }

}
