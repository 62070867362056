import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CoreService {

 //private rootpath= 'http://localhost:4200/#/home
  
  private rootPath = environment.rootpath; // 'http://localhost:5000/api/candidate';
  private appName  = "candidate";
  private userType = "candidate";
  constructor() { }


  public getRootPath () {
    return this.rootPath;
  }

  public getAppName (){
    return this.appName;
  }

  public getUserType (){
    return this.userType;
  }
}


