import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpRequest, HttpResponse, HttpHandler, 
  HttpHeaders, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { CoreService } from '../core/core.service';
import { tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class InterceptorService {



  constructor(private authenticationService: AuthenticationService,
    private coreService: CoreService,
    private router: Router) {

  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var authReq = null;
    var isUserLoggedIn = this.authenticationService.isLoggedIn();
    var user = this.authenticationService.getUserDetails();
    var token = this.authenticationService.getAuthToken();

    if (!token && user) {
      token = user.ptoken;
    }
    if (isUserLoggedIn) {
      authReq = req.clone({
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': token,
          'appname': this.coreService.getAppName (),
          'usertype': this.coreService.getUserType ()
        })
      });
    } else {
      authReq = req.clone({
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'appname': this.coreService.getAppName (),
          'usertype': this.coreService.getUserType ()
        })
      });
    } 

    return next
    .handle(authReq)
    .pipe(
      tap((ev: HttpEvent<any>) => {
        if (ev instanceof HttpResponse) {
         // console.log('Here is event ..', ev);
          if (ev.body && ev.body.code == 403) {
          //  console.log('Session Expired');
            this.authenticationService.saveLogin (null);
            this.router.navigate(['home']);
            return;
          }
        }
      })
    )


  }

}
