import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';
@Injectable({
  providedIn: 'root'
})
export class EducationalDetailsService {


  private path = '/professional-qualification';
  private edupath = '/edu-details';

  constructor(
    private backendService: BackendService
  ) { }


  saveProfDetails(currentProfQual: any) {

    return this.backendService.post(this.path + '/save', { currentProfQual: currentProfQual });
  }


  getProfDetails() {

    return this.backendService.get(this.path);
  }

  deleteProfDetails(langId: string) {
    return this.backendService.get(this.path + '/delete/' + langId);
  }



  saveEducationalD(data: any) {

    return this.backendService.post(this.edupath, data);
  }

  getEducationalD() {

    return this.backendService.get(this.edupath);
  }

  deleteEducationalD(eduid: string) {

    return this.backendService.get(this.edupath + '/delete/' + eduid);
  }

  matchEducationalD(ename: string) {

    return this.backendService.get(this.edupath + '/eduname/' + ename);
  }
}
