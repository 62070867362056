import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service' ;
@Injectable({
  providedIn: 'root'
})

export class ConstantService {
  private path = '/constant';
  constructor(
    private backendService : BackendService
  ) { }


  getConstantValue(code) {
    
    return this.backendService.get(this.path + '/code/'+ code);
  }

  getStates (){
    return this.backendService.get(this.path + '/states');
  }
  
  getDistricts (state){
    return this.backendService.post(this.path + '/districts', {state: state});
  }
}
