import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';
import { StorageService } from '../storage/storage.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {


  private path = '/authentication';
  private otp_path = '/otp';
  private userSessionKey = 'user';

  private onLoginChange = new BehaviorSubject <any> (null);

  constructor(private backendService: BackendService,
    private storageService: StorageService) { }


    onLogin (){
      return this.onLoginChange.asObservable ();
    }


  login(username: string, password: string, captcha: string, page: string) {
    return this.backendService.post(this.path + "/login",
      {
        username: username, password: password,
        captcha: captcha, page: page
      });
  }


  saveLogin(userDetails: any) {
   
    this.storageService.save(this.userSessionKey, userDetails);
    if (userDetails){
      this.onLoginChange.next (true);
    }
  }

  isLoggedIn() {
    const user = this.storageService.get(this.userSessionKey);

    if (user && user.token) {
      return true;
    } else {
      return false;
    }
  }

  me() {
    return this.storageService.get(this.userSessionKey);
  }

  getAuthToken() {
    const user = this.storageService.get(this.userSessionKey);
    if (!user) {
      return null;
    } else {
      return user.token;
    }
  }

  logout() {
    this.onLoginChange.next (false);
    return this.backendService.get(this.path + '/logout');
  }

  getUserDetails() {
    return this.storageService.get(this.userSessionKey);
  }

  register(username: string, password: string, phone: string, captcha: string, page: string) {
    let data = {
      username: username,
      password: password,
      phone: phone,
      captcha: captcha,
      page: page
    };
    return this.backendService.post("/register", data);
  }

  validateOTP(otp: string, token: string) {
    return this.backendService.post(this.otp_path + "/validate",
      {
        otp: otp, session_id: token
      });
  }

  resendOTP(token: string, page: string) {
    return this.backendService.post(this.otp_path + "/resend",
      {
        session_id: token,
        page: page
      });
  }

  getPasswordSetting() {
    return this.backendService.get(this.path + "/settings/password");
  }

  getMyInfo() {
    return this.backendService.get(this.path + "/me");
  }

}
