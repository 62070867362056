import { Component, OnInit, Input } from '@angular/core'; 
 

@Component({
  selector: 'app-candidate-menu',
  templateUrl: './candidate-menu.component.html',
  styleUrls: ['./candidate-menu.component.scss']
})
export class CandidateMenuComponent implements OnInit {

  @Input() language:any;

  

  constructor(
     
  ) { 
    
  }

  ngOnInit() {
    
  }

  
}
