import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'; 
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { SimpleLayoutComponent } from './containers/simple-layout/simple-layout.component'; 
import {AppRoutingModule} from './app-routing.module';
  
import {CoreService, 
      BackendService, 
      AuthenticationService, 
      HomeService, 
      InterceptorService,
      StorageService,
      CaptchaService,
      AuthguardService, 
      WindowRef,
      PaymentService,
      ApplicationService
     } from './shared/services';
import { SharedModule } from './shared/shared.module';


 


@NgModule({
  declarations: [
    AppComponent,
    SimpleLayoutComponent,
   
    // AddressComponent
   
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule, 
    NgbModule,
    SharedModule 
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
  
    CoreService,
    BackendService,
    AuthenticationService,
    HomeService, 
    StorageService,
    CaptchaService,
    AuthguardService,
    WindowRef,
    PaymentService,
    ApplicationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
