import { Component, OnInit, OnDestroy } from '@angular/core';
import { HomeService, CoreService, StoreService } from '../../shared/services'; 
import { first } from "rxjs/operators";

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss']
})
export class HomeLayoutComponent implements OnInit, OnDestroy {

  data: any = {};
  dataAvailable = false;
  storeSub: any;
  language: any;
  bgUrl: any;
  apiUrl: any;
  banner_width: any = "100%";

  constructor(private homeService: HomeService,
    private store: StoreService,
    private coreService: CoreService

  ) {
    // console.log ("ctreating container for home layout...");

  }

  ngOnInit() {
    this.apiUrl = this.coreService.getRootPath();
    this.homeService.getSettings()
      .pipe(first())
      .subscribe(res => {

        this.data = res;
        this.store.onSett({ data: res });
        this.dataAvailable = true;
        this.bgUrl = this.buildUrl(this.data.settings.header.bgimage);
        this.listenToLang();
      });



  }

  listenToLang() {
    this.storeSub = this.store.getLang()
      .subscribe(lang => {
        this.language = lang;
        // console.log ("**** he language has been change d", lang);
      });

  }

  buildUrl(url) {
    url = this.apiUrl + url;

    // var url  = this.coreService.getRootPath() + "/document/type/" + this.docType;
    //  url += "?t=" + t;
    url += "?appname=" + this.coreService.getAppName();
    url += "&usertype=" + this.coreService.getUserType();
    return url;
  }



  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }

}
