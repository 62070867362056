import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { Routes, RouterModule } from '@angular/router';
import { HomeHeaderComponent } from './components/home-header/home-header.component';
import { PaymentComponent } from './components/payment/payment.component';
import { SafehtmlPipe, QDatePipe } from './pipes';
import { HomeMenuComponent } from './components/home-menu/home-menu.component';
import { UploaderComponent } from './uploader/uploader.component';
import { HomeFooterComponent } from './components/home-footer/home-footer.component';
import { RequiredComponent } from './components/required/required.component';
import { AlertComponent } from '../shared/alert/alert.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { FullLayoutComponent } from '../containers';
import { CandidateMenuComponent } from '../candidate/candidate-menu/candidate-menu.component';
import { MenuHeaderComponent } from '../candidate/menu-header/menu-header.component';
import { MenuFooterComponent } from '../candidate/menu-footer/menu-footer.component';
import { MenuNavComponent } from '../candidate/menu-nav/menu-nav.component';
import { MainBodyComponent } from '../candidate/main-body/main-body.component';

//SafehtmlPipe


@NgModule({
  declarations: [HomeHeaderComponent, AlertComponent,
    HomeFooterComponent, 
    SafehtmlPipe, 
    QDatePipe,
    HomeMenuComponent,
    UploaderComponent ,
    PaymentComponent,
    RequiredComponent,
    PaginationComponent,
    FullLayoutComponent,
    CandidateMenuComponent, 
    MenuHeaderComponent, 
    MenuFooterComponent, 
    MenuNavComponent,
    MainBodyComponent,
     ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    RouterModule
  ],
  exports: [
    HomeHeaderComponent, 
    HomeFooterComponent,
    HomeMenuComponent, 
    SafehtmlPipe,
    QDatePipe,
    UploaderComponent,
    PaymentComponent,
    RequiredComponent,
    AlertComponent,
    PaginationComponent,
    FullLayoutComponent,
    MenuNavComponent,
  ]
})
export class SharedModule { }
