<div class="row raised-menu" [ngStyle]="getFirstStyles()">
    <div class="col-md-12">
    <nav class="navbar navbar-expand-lg rm-nav">
 
        <div class="collapse navbar-collapse justify-content-center" style="font-size: 1.2rem;">
          <ul class="navbar-nav font-weight-bold" *ngIf="lang">
            <li class="nav-item" [ngClass]="{'active': isHome()}" [ngStyle]="getThirdStyles('/home')">
              <a class="nav-link" [routerLink]="['/']">{{data.labels.HOME.MENU_HOME[lang.code]}}</a>
            </li>
            <li class="nav-item" [ngClass]="{'active': isFaq()}" [ngStyle]="getThirdStyles('/home/faq')">
              <a class="nav-link" [routerLink]="['faq']">{{data.labels.HOME.MENU_FAQ[lang.code]}}</a>
            </li>
            <li class="nav-item" *ngIf="showMenu (data.labels.HOME.MENU_TESTCENTERLIST)"  [ngClass]="{'active': isTestcenter()}" [ngStyle]="getThirdStyles('/home/testcenter')">
              <a class="nav-link" [routerLink]="['testcenter']">{{data.labels.HOME.MENU_TESTCENTERLIST[lang.code]}}</a>
            </li>
            <li class="nav-item" *ngIf="showMenu (data.labels.HOME.MENU_PRACTICETEST)"  [ngClass]="{'active': isPracticetest()}" [ngStyle]="getThirdStyles('/home/practicetest')">
              <a class="nav-link" [routerLink]="['practicetest']">{{data.labels.HOME.MENU_PRACTICETEST[lang.code]}}</a>
            </li>
            <li class="nav-item"  [ngClass]="{'active': isContactus()}" [ngStyle]="getThirdStyles('/home/contactus')">
              <a class="nav-link" [routerLink]="['contactus']">{{data.labels.HOME.MENU_CONTACTUS[lang.code]}}</a>
            </li>

            <li class="nav-item" *ngIf="user" [ngStyle]="getThirdStyles('/candidate/dashboard')">
                <a class="nav-link" [routerLink]="['/candidate/dashboard']">Dashboard</a>
              </li> 
          </ul>
        </div>
      </nav>
      </div>
      </div>