<!-- <button id="rzp-button1" class="btn btn-info px-4" (click)="initPay();">Pay</button> -->

 
<a *ngIf="!isPaid" id="rzp-button1" href="javascript:void(0);" (click)="initPay();">Make Payment</a>
<div *ngIf="isPaid">
    Already paid.
</div>

 
 
 