import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService, StoreService } from 'src/app/shared/services';
import { defaultLanguage } from '../../models/language'; 
import { first } from "rxjs/operators";

@Component({
  selector: 'app-menu-nav',
  templateUrl: './menu-nav.component.html',
  styleUrls: ['./menu-nav.component.scss']
})
export class MenuNavComponent implements OnInit {

  @Input() language: any;
  menus = [];
  storeSub: any;
  headerData: any;


  constructor(private router: Router,
    private homeService: HomeService,
    private store: StoreService) { }

  ngOnInit() {

    this.listenToSettings();
    this.homeService.getMenu()
      .pipe(first())
      .subscribe(res => {
        this.menus = res["menu"];
        // console.log(this.menus)
        this.menus = this.menus.filter(el => el.text !== "CAND_MENU_APPLICATION");
        // console.log(this.menus)
        this.initRouteSelection();
      });

  }

  listenToSettings() {
    this.storeSub = this.store.getSett()
      .subscribe(data => {
        this.headerData = data;

      });
  }

  initRouteSelection() {
    //console.log ("you are herer ..", this.router.url);
    var currUrl = this.router.url.toLowerCase();
    for (var i = 0; i < this.menus.length; i++) {
      var target = this.menus[i].target.toLowerCase();

      this.menus[i].active = currUrl.indexOf(target) >= 0;


    }
  }

  isActive(menuItem) {
    var selectedUrl = '/' + menuItem.target;
    if (this.router.url.toLowerCase() == selectedUrl) {
      return true;
    } else {
      return false;
    }
  }



  getMenuText(menu) {
    //later do it with api.
    if (!this.language) {
      console.log("*** never be printed. why is lang not there..");
    }

    let len = 35;

    let text = menu.display[defaultLanguage.code];
    if (!text) {
      return "";
    }
    if (text.length > len) {
      return text.substr(0, len) + "...";
    }
    return text;
  }


  getFirstStyles() {
    var setting = {};
    if (this.headerData && this.headerData.data) {
      setting = {
        'background-color': this.headerData.data.settings.header.firstoption ? this.headerData.data.settings.header.firstoption : '#001f3f',
        'color': 'white'
      }
    }
    return setting;
  }

  getSecondStyles(menuItem) {
    var selectedUrl = '/' + menuItem.target;
    var setting = {};
    if (this.router.url.toLowerCase() == selectedUrl) {

      if (this.headerData && this.headerData.data) {
        setting = {
          'background-color': this.headerData.data.settings.header.thirdoption ? this.headerData.data.settings.header.thirdoption : '#e0ac69',
          'color': 'white'
        }
      }
    } else {
      if (this.headerData && this.headerData.data) {
        setting = {
          'background-color': this.headerData.data.settings.header.firstoption ? this.headerData.data.settings.header.firstoption : '#e0ac69',
          'color': 'white'
        }
      }

    }
    return setting;
  }


  onMenuClick(menu) {
    for (var i = 0; i < this.menus.length; i++) {
      this.menus[i].active = false;
    }

    menu.active = true;

    if (menu.absolute){
      window.open (menu.target);
    }else{
    this.router.navigate([menu.target]);
    }


  }

}
