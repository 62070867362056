import { Component, OnInit, Input, OnDestroy} from '@angular/core';
import { HomeService, CoreService, AuthenticationService, StoreService } from '../../../shared/services'; 
import { Router } from '@angular/router';
import { defaultLanguage } from '../../../models/language'; 
import { first } from "rxjs/operators";


@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss']
})
export class HomeHeaderComponent implements OnInit, OnDestroy {

  @Input() data: any;
  currentLanguage: any = {};
  apiUrl: any;
  user: any = {};
  logoUrl: any;
  headerImageUrl: any;
  banner_width: any = "100%";
  menus = [];
  menu = [];
  public pushRightClass: string;
  headerData: any;
  storeSub: any;
  authSub:any;
  lang:any;
  headerText:any;
  storeLangSub:any;






  constructor(private homeService: HomeService,
    private coreProvider: CoreService,
    private store: StoreService,
    private authenticationService: AuthenticationService,
    private router: Router) {}

  ngOnInit() {
    this.lang =  defaultLanguage;

    this.subscribeToStore();

    this.homeService.getMenu()
      .subscribe( (res:any) => {
        
        if (res.status  == "error"){
          return;
        }
        this.menu = res["menu"];
        this.initRouteSelection();
        
      });
      this.authSub = this.authenticationService.onLogin ().subscribe (res=>{
        if (res){
          this.initMenu();
        }else {
          this.menus = [];
        }
      });
      
      

    this.apiUrl = this.coreProvider.getRootPath();
    this.user = this.authenticationService.getUserDetails();
    this.logoUrl = this.buildUrl(this.data.settings.header.logo);
    this.headerImageUrl = this.buildUrl(this.data.settings.header.banner);


    //if (this.showLanguage()) {
      this.currentLanguage = this.data.languages[0];
      this.store.onLang(this.currentLanguage);
   // }


   //console.log ("what is user --", this.user);
   
  }


  ngOnDestroy (){
    if (this.authSub){
      this.authSub.unsubscribe ();
    }
    if (this.storeSub){
      this.storeSub.unsubscribe();
    }  
    if (this.storeLangSub){
      this.storeLangSub.unsubscribe();
    }

  }
  

  getMenuText(menu) {
    //later do it with api.
    // if (!this.language){
    //   console.log ("*** never be printed. why is lang not there..");
    // }

    let len = 30;

    let text = menu.display[defaultLanguage.code];
    if (!text) {
      return "";
    }
    if (text.length > len) {
      return text.substr(0, len) + "...";
    }
    return text;
  }

  onMenuClickBtn(menu) {
    for (var i = 0; i < this.menu.length; i++) {
      this.menu[i].active = false;
    }

    menu.active = true;

    this.router.navigate([menu.target]);


  }

  initRouteSelection() {
    //console.log ("you are herer ..", this.router.url);
    var currUrl = this.router.url.toLowerCase();
    for (var i = 0; i < this.menu.length; i++) {
      var target = this.menu[i].target.toLowerCase();

      this.menu[i].active = currUrl.indexOf(target) >= 0;


    }
  }

  isActive(menuItem) {
    var selectedUrl = '/' + menuItem.target;
    if (this.router.url.toLowerCase() == selectedUrl) {
      return true;
    } else {
      return false;
    }
  }

  getFirstStylesMenu() {
    var setting = {};
    if (this.headerData && this.headerData.data && this.headerData.data.settings) {
      setting = {
        'background-color': this.headerData.data.settings.header.firstoption ? this.headerData.data.settings.header.firstoption : '#001f3f',
        'color': 'white'
      }
    }
    return setting;
  }


  getSecondStylesMenu(menuItem) {
    var selectedUrl = '/' + menuItem.target;
    var setting = {};
    if (this.router.url.toLowerCase() == selectedUrl) {

      if (this.headerData && this.headerData.data) {
        setting = {
          'background-color': this.headerData.data.settings.header.thirdoption ? this.headerData.data.settings.header.thirdoption : '#e0ac69',
          'color': 'white'
        }
      }
    } else {
      if (this.headerData && this.headerData.data) {
        setting = {
          'background-color': this.headerData.data.settings.header.firstoption ? this.headerData.data.settings.header.firstoption : '#e0ac69',
          'color': 'white'
        }
      }

    }
    return setting;
  }

  subscribeToStore() {
    this.storeSub = this.store.getSett().subscribe(data => {
      //console.log ("WHAT IS MY DAT A>>>", data);
      this.headerData = data;
      this.updateValues ();
    });
    this.storeLangSub = this.store.getLang().subscribe (lang=>{
      this.currentLanguage = lang;
      this.updateValues ();
  });
  }

  isBilingual (){
    if (!this.data){
      return false;
    }
    return this.data.settings.language.bilingual;
  }
  getSeparator (){
    return '<div class="rm-sb" ></div>';
  }

  updateValues (){
    if (!this.currentLanguage ||   !this.headerData){
      return;
    }

    var header = this.data.settings.header.bannerText[this.currentLanguage.code];

    if (this.isBilingual()){
      header = this.data.settings.header.bannerText[this.store.defaultLanguage.code];
       
     
      var otherLang = this.data.languages.find (x => x.code != this.store.defaultLanguage.code);
      if (!otherLang){
        console.log ("setting problem.. why no other lnaguage found?");
      }

      header += this.getSeparator () + this.data.settings.header.bannerText[otherLang.code];

    }

    this.headerText = header;

     
  }







  getFirstStyles() {
    var setting = {};
    if (this.data && this.data.settings) {
      setting = {
        'background-color': this.data.settings.header.firstoption ? this.data.settings.header.firstoption : '#001f3f',
        'color': 'white'
      }
    }
    return setting;
  }


  getSecondStyles() {
    var setting = {};
    if (this.data && this.data.settings) {
      setting = {
        'background-color': this.data.settings.header.secondoption ? this.data.settings.header.secondoption : '#117a8b',
        'color': 'white'
      }
    }
    return setting;
  }

  initMenu() {
    this.menus = [];

    this.menus.push({
      icon: 'fas fa-key',
      text: 'Change Password',
      key: 'CHANGEPASSWORD'
    });

    this.menus.push({
      icon: 'fas fa-user',
      text: 'Profile',
      key: 'PROFILE'
    });

    this.menus.push({
      icon: 'fas fa-lock',
      text: 'Logout',
      key: 'LOGOUT'
    });

  }


  buildUrl(u) {
   let  url = this.apiUrl + u;

    // var url  = this.coreService.getRootPath() + "/document/type/" + this.docType;
    //  url += "?t=" + t;
    url += "?appname=" + this.coreProvider.getAppName();
    url += "&usertype=" + this.coreProvider.getUserType();
    return url;
  }

  onLanguageChange(lang) {
    this.currentLanguage = lang;
    this.store.onLang(this.currentLanguage); 
  }

  getVisibleLangs(langs) {
    if (!this.data.settings.language.bilingual) {
      return langs;
    }
    var l = [];

    for (var i = 0; i < langs.length; i++) {
      if (langs[i].code != 'english') {
        l.push(langs[i]);
      }
    }
    return l;
  }


  onMenuClick(key) {


    if (key == "LOGOUT") {
      this.logout();
    }
    else if (key == "CHANGEPASSWORD") {
      this.onChangepassword();
    }
    else if (key == "PROFILE") {
      this.onProfile();
    }
  }


  onLogin() {

    this.router.navigate(['/home/login']);
  }

  onRegister() {

    this.router.navigate(['/home/register']);
  }

  onChangepassword() {

    this.router.navigate(['/candidate/change-password']);
  }

  onProfile() {

    this.router.navigate(['/candidate/profile']);
  }

  logout() {

    this.authenticationService.logout()
    .pipe(first())
      .subscribe(res => {
        this.authenticationService.saveLogin(null);
        this.router.navigate(['home']);
      });
  }


  showLanguage() {
    // return true;

    //console.log ("shoiudl I show lang ");
    if (this.data.settings.language.english_only) {
      return false;
    }
    if (this.data.settings.language.bilingual) {
      if (this.data.languages.length > 2) {
        return true;
      } else {
        return false;
      }
    }

    if (this.data.languages.length > 1) {
      return true;
    }


    return false;

  }

  openNav() {
    document.getElementById("mySidenav").style.width = "250px";
    console.log ("what is menu ", this.menu, " and  lang - ", this.lang);
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0px";
  }

  isActiveMenu (m){
    let path = this.router.url;
    return path.indexOf ( m.toLowerCase()) >= 0;
  //  console.log ("here is path => ", path, " and ", m);
      
  }

  isHome (){
    this.getFirstStyles();
    return this.router.url == '/home';    
  }

  getFirstStyle() {
     
    var set = {
      'background-color': this.data.settings.header.firstoption ? this.data.settings.header.firstoption : '#001f3f',
      'color': 'white'
    }
    return set;
 }


 getThirdStyles(menuItem) {
  
  var set={};
  if(this.router.url.toLowerCase() == menuItem){
 
  if(this.data && this.data.settings){
    set = {
    'background-color': this.data.settings.header.thirdoption ? this.data.settings.header.thirdoption : '#e0ac69',
    'color': 'white'
  }
 }
}else{
  if(this.data && this.data.settings){
    set = {
      'background-color': this.data.settings.header.firstoption ? this.data.settings.header.firstoption : '#e0ac69',
      'color': 'white'
    }
   }

}
  return set;
}

  isFaq(){
    return this.router.url == '/home/faq';    
  }
  
  isTestcenter(){
    return this.router.url == '/home/testcenter';    
  }

  isPracticetest(){
    return this.router.url == '/home/practicetest';    
  }

  isContactus(){
    return this.router.url == '/home/contactus';    
  }
}
