import { Injectable } from '@angular/core';
import {BackendService} from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private backendService:BackendService) { }

  getSettings (){
    return this.backendService.get ("/home");
  }
  getMenu (){
    return this.backendService.get ("/home/menu");
  }

  
}
