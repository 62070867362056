import { Injectable } from '@angular/core';
import { BackendService } from './backend/backend.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreService {


    public language = new BehaviorSubject <any> ({});
    public settings = new BehaviorSubject <any> ({});

    public defaultLanguage = {code: 'english', name: "English"};

  constructor(private backendService:BackendService) { }
  
  public getLang () {
      return this.language.asObservable ();
  }

  public getSett (){
    return this.settings.asObservable ();
  }

  public onLang (l){
      this.language.next (l);
  }

  public onSett (s){
    //console.log ("SEND THE ______", s);
      return this.settings.next (s);
  }
   

   

}